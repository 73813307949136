import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardWizzim, NotFoundComponent } from 'common-shared-if';

const routes: Routes = [
  {
    path: 'ticket',
    loadChildren: './modules/ticket/ticket.module#TicketModule',
    canActivate: [AuthGuardWizzim]
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'adv',
        loadChildren: () => import('./modules/dashboard/adv/adv.module').then(m => m.AdvModule),
        canActivate: [AuthGuardWizzim]
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
